<script setup lang="ts">
import { CmsPage as CmsPageType } from '@shopware-pwa/types';

const { clearBreadcrumbs } = useBreadcrumbs();

// Get props
const props = defineProps<{
  cmsPage: CmsPageType;
}>();

// Clear breadcrumbs
onBeforeRouteLeave(() => {
  clearBreadcrumbs();
});
</script>

<template>
  <CmsPage v-if="props.cmsPage" :content="props.cmsPage" />
</template>
